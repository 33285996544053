var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"position":"relative"}},[_c('v-navigation-drawer',{attrs:{"permanent":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"menu-crm",attrs:{"dense":"","nav":""}},[_c('v-list-item-group',{attrs:{"color":"#704ad1"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.computedItems),function(item,index){return _c('v-list-item',{key:index,staticClass:"item-menu",class:{
            'v-list-item--active-simlpe-item ':
              _vm.computedActiveRouter(item) && !item.groups,
            'v-list-item--active-group-item ':
              item.groups && _vm.computedActiveRouter(item.groups)
          },attrs:{"color":"#704ad1"}},[(item && item.groups && item.groups.length)?_c('div',{staticClass:"group-content"},[(_vm.etatNavBarSetting)?_c('div',{class:{
                'v-list-item--active-simlpe-item-group': _vm.computedActiveRouter(
                  item.groups
                )
              }},[_c('router-link',{staticClass:"item-menu-group",attrs:{"color":"#704ad1","to":item.groups[0].href},nativeOn:{"click":function($event){item.selected = !item.selected}}},[_c('v-list-item-icon',[_c('font-awesome-icon',{attrs:{"icon":item.icon}})],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-list-item-icon',{staticClass:"icon- mt-3 ml-0"},[_c('font-awesome-icon',{attrs:{"icon":item.iconGroup}})],1)],1)],1):_vm._e(),(item.selected || !_vm.etatNavBarSetting)?_vm._l((item.groups),function(itemG,indexG){return _c('div',{key:indexG,class:{
                  ' v-list-item--active-simlpe-item v-list-item--active-simlpe-item-normal':
                    _vm.computedActiveRouter(itemG) && _vm.etatNavBarSetting,
                  'v-list-item--active-simlpe-item p-2':
                    _vm.computedActiveRouter(itemG) && !_vm.etatNavBarSetting
                }},[_c('router-link',{staticClass:"d-flex",class:{
                    ' pl-3 ':
                      !_vm.computedActiveRouter(item.groups) && _vm.etatNavBarSetting,

                    'pl-6':
                      _vm.computedActiveRouter(item.groups) && _vm.etatNavBarSetting,
                    'pl-3 pt-2 pb-2':
                      _vm.computedActiveRouter(item.groups) && !_vm.etatNavBarSetting,
                    'pl-1 pt-2 pb-2':
                      !_vm.computedActiveRouter(item.groups) && !_vm.etatNavBarSetting
                  },attrs:{"link":"","color":"#704ad1","to":itemG.href},nativeOn:{"click":function($event){return _vm.updateSelectedItem(index)}}},[[(_vm.etatNavBarSetting)?_c('v-list-item-icon',[_c('font-awesome-icon',{attrs:{"icon":itemG.icon}})],1):_c('v-tooltip',{attrs:{"bottom":"","color":"#311B92"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('font-awesome-icon',_vm._g(_vm._b({attrs:{"icon":itemG.icon}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"pl-2 pr-2"},[_vm._v(_vm._s(item.title)+" / "+_vm._s(itemG.title))])]),(_vm.etatNavBarSetting)?_c('v-list-item-content',[_vm._v(" "+_vm._s(itemG.title)+" ")]):_vm._e()]],2)],1)}):_vm._e()],2):_c('div',[_c('router-link',{staticClass:"d-flex",attrs:{"to":{ path: item.href }}},[[(_vm.etatNavBarSetting)?_c('v-list-item-icon',[_c('font-awesome-icon',{attrs:{"icon":item.icon}})],1):_c('v-tooltip',{attrs:{"bottom":"","color":"#311B92"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('font-awesome-icon',_vm._g(_vm._b({attrs:{"icon":item.icon}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"pl-2 pr-2"},[_vm._v(_vm._s(item.title))])]),(_vm.etatNavBarSetting)?_c('v-list-item-content',[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()]],2)],1)])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }