<template>
  <v-card style="position:relative">
    <v-navigation-drawer v-model="drawer" permanent>
      <v-list dense nav class="menu-crm">
        <v-list-item-group color="#704ad1" v-model="selectedItem">
          <v-list-item
            v-for="(item, index) in computedItems"
            :key="index"
            class="item-menu"
            color="#704ad1"
            :class="{
              'v-list-item--active-simlpe-item ':
                computedActiveRouter(item) && !item.groups,
              'v-list-item--active-group-item ':
                item.groups && computedActiveRouter(item.groups)
            }"
          >
            <div
              v-if="item && item.groups && item.groups.length"
              class="group-content"
            >
              <div
                :class="{
                  'v-list-item--active-simlpe-item-group': computedActiveRouter(
                    item.groups
                  )
                }"
                v-if="etatNavBarSetting"
              >
                <router-link
                  @click.native="item.selected = !item.selected"
                  class="item-menu-group"
                  color="#704ad1"
                  :to="item.groups[0].href"
                >
                  <v-list-item-icon>
                    <font-awesome-icon :icon="item.icon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ item.title }}
                  </v-list-item-content>
                  <v-list-item-icon class="icon- mt-3 ml-0">
                    <font-awesome-icon :icon="item.iconGroup" />
                  </v-list-item-icon>
                </router-link>
              </div>

              <template v-if="item.selected || !etatNavBarSetting">
                <div
                  v-for="(itemG, indexG) in item.groups"
                  :class="{
                    ' v-list-item--active-simlpe-item v-list-item--active-simlpe-item-normal':
                      computedActiveRouter(itemG) && etatNavBarSetting,
                    'v-list-item--active-simlpe-item p-2':
                      computedActiveRouter(itemG) && !etatNavBarSetting
                  }"
                  :key="indexG"
                >
                  <router-link
                    @click.native="updateSelectedItem(index)"
                    link
                    class=" d-flex"
                    color="#704ad1"
                    :to="itemG.href"
                    :class="{
                      ' pl-3 ':
                        !computedActiveRouter(item.groups) && etatNavBarSetting,

                      'pl-6':
                        computedActiveRouter(item.groups) && etatNavBarSetting,
                      'pl-3 pt-2 pb-2':
                        computedActiveRouter(item.groups) && !etatNavBarSetting,
                      'pl-1 pt-2 pb-2':
                        !computedActiveRouter(item.groups) && !etatNavBarSetting
                    }"
                  >
                    <template>
                      <v-list-item-icon v-if="etatNavBarSetting">
                        <font-awesome-icon :icon="itemG.icon" />
                      </v-list-item-icon>
                      <v-tooltip bottom color="#311B92" v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <font-awesome-icon
                            v-on="on"
                            v-bind="attrs"
                            :icon="itemG.icon"
                          />
                        </template>
                        <span class="pl-2 pr-2"
                          >{{ item.title }} / {{ itemG.title }}</span
                        >
                      </v-tooltip>

                      <v-list-item-content v-if="etatNavBarSetting">
                        {{ itemG.title }}
                      </v-list-item-content>
                    </template></router-link
                  >
                </div>
              </template>
            </div>
            <div v-else>
              <router-link :to="{ path: item.href }" class="d-flex">
                <template>
                  <v-list-item-icon v-if="etatNavBarSetting">
                    <font-awesome-icon :icon="item.icon" />
                    <!-- <v-icon>{{ item.icon }}</v-icon> -->
                  </v-list-item-icon>
                  <v-tooltip bottom color="#311B92" v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <font-awesome-icon
                        v-on="on"
                        v-bind="attrs"
                        :icon="item.icon"
                      />
                    </template>
                    <span class="pl-2 pr-2">{{ item.title }}</span>
                  </v-tooltip>
                  <v-list-item-content v-if="etatNavBarSetting">
                    {{ item.title }}
                  </v-list-item-content>
                </template></router-link
              >
            </div>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    etatNavBarSetting: { default: true }
  },
  data() {
    return {
      selectedItem: -1,
      drawer: true,
      mini: false
    };
  },
  computed: {
    ...mapGetters(['getUserData', 'checkPermission']),
    computedItems() {
      let items = [
        {
          title: 'Retour',
          href: '/cdg',
          icon: ['fal', 'circle-caret-left'],
          permission: true
        },
        {
          title: 'Informations personnelles',
          href: '/setting/personal-info',
          icon: ['fal', 'circle-info'],
          permission: true
        },
        {
          title: 'Gestion fiche',
          href: '/setting/configurationfiche',
          icon: ['fal', 'objects-column'],
          permission: this.checkPermission('GFTH')
        },
        {
          title: 'Calendrier TH',
          href: '/setting/calender-th',
          icon: ['fal', 'calendar-pen'],
          permission: this.checkPermission('CTTH')
        },
        // {
        //   title: 'Répartition des zones',
        //   href: '/setting/repartition-zone-th',
        //   icon: 'mdi-map-clock',
        //   permission: this.checkPermission('RZTH')
        // },
        {
          title: 'Gestion des configurations',
          href: '/setting/gestion-configuration',
          icon: ['fal', 'calculator-simple'],
          permission: this.checkPermission('GLCTH')
        },
        {
          title: 'Gestion des sociétés',
          href: '/setting/gestion-filiale-th',
          icon: ['fal', 'building'],
          permission: this.checkPermission('GS')
        },
        {
          title: 'Gestion des clients passager',
          href: '/setting/gestion-client-passage',
          icon: ['fal', 'person-shelter'],
          permission: this.checkPermission('GCP')
        },

        {
          title: 'Gestion des banques',
          href: '/setting/gestion-banque-th',
          icon: ['fal', 'building-columns'],
          permission: this.checkPermission('GDB')
        },
        {
          title: 'Gestion des organismes',
          href: '/setting/organisme-new-th',
          icon: ['fal', 'building-memo'],
          permission: this.checkPermission('GOTH')
        },
        {
          title: 'Gestion des templates',
          icon: ['fal', 'folder-gear'],
          iconGroup: ['fal', 'chevron-down'],
          permission: true,
          selected: false,
          groups: [
            {
              title: 'Templates',
              href: '/setting/template-th',
              icon: ['fal', 'file-code'],
              permission: this.checkPermission('GTTH')
            },
            {
              title: 'Gestion des groupes',
              href: '/setting/groupe-template-th',
              icon: ['fal', 'list-ul'],
              permission: this.checkPermission('GGPTTH')
            }
          ]
        },
        {
          title: 'Gestion des utilisateurs',
          href: '/setting/users',
          icon: ['fal', 'users'],
          permission: this.checkPermission('GUTH')
        },
        {
          title: 'Gestion des factures libre',
          icon: ['fal', 'file-circle-plus'],
          iconGroup: ['fal', 'chevron-down'],
          permission: true,
          selected: false,
          groups: [
            {
              title: 'Gestion des catégories',
              href: '/setting/gestion-categories',
              icon: ['fal', 'shapes'],
              permission: this.checkPermission('GCATTH')
            },
            {
              title: 'Gestion des produits',
              href: '/setting/gestion-produit',
              icon: ['fal', 'table'],
              permission: this.checkPermission('GPTH')
            }
          ]
        },
        {
          title: 'Gestion des vues',
          href: '/setting/vue',
          icon: ['fal', 'eye'],
          permission: this.checkPermission('GVTH')
        },
        {
          title: 'Gestion des modèles exportation',
          href: '/setting/modele-export',
          icon: ['fal', 'file-export'],
          permission: this.checkPermission('GMETH')
        },
        {
          title: 'Gestion des biens',
          href: '/setting/gestion-des-biens',
          icon: ['fal', 'house-building'],
          permission: this.checkPermission('GBHT')
        },
        {
          title: 'Gestion type des sociétes',
          href: '/setting/gestion-type-societe',
          icon: ['fal', 'list-timeline'],
          permission: this.checkPermission('GTSFL')
        },
        {
          title: 'Gestion configuration mensuel',
          href: '/setting/configurationMensuel',
          icon: ['fal', 'calendar-week'],
          permission: this.checkPermission('GCM')
        },
        {
          title: 'Gestion des services',
          href: '/setting/gestion-services',
          icon: ['fal', 'bell-concierge'],
          permission: this.checkPermission('GSHT')
        },
        {
          title: 'Configuration du paiement',
          href: '/setting/configuration-paiement/gestion-conditions-paiement',
          icon: ['fal', 'credit-card-front'],
          permission:
            this.checkPermission('GTRHT') &&
            (this.checkPermission('GTPHT') || this.checkPermission('GCPHT'))
        }
      ];

      return items.filter(item => item.permission == true);
    },
    computedActiveRouter() {
      return function(item) {
        if (Array.isArray(item)) {
          let urls = item.map(i => i.href);
          return urls.includes(this.$route.path);
        }
        let urls_child = this.$route.matched.map(i => i.path);
        return (
          item.href == this.$route.path ||
          (urls_child && urls_child.includes(item.href))
        );
      };
    }
  },

  methods: {
    updateSelectedItem(index) {
      this.selectedItem = index;
    }
  }
};
</script>
<style scoped lang="scss">
.menu-crm {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  flex-wrap: nowrap !important;
  height: 100%;
  a {
    color: #2f2f2f !important;
    font-size: 11px;
    font-weight: 600;
    padding: 4px 5px;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
    text-align: center;
  }
  svg {
    height: 18px !important;
    min-width: 18px !important;
  }
}
.menu-crm .item-menu-group {
  margin-bottom: 0px !important;
  min-height: 40px !important;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0px 4px !important;
}
.v-list-item--active-group-item {
  padding: 0px !important;
  .item-menu-group {
    padding: 0px 11px !important;
    background-color: #eee9fa !important ;
  }
  .v-list-item--active-simlpe-item-group {
    width: 100%;
    border-radius: 5px;

    .v-icon.v-icon,
    svg,
    .theme--light.v-icon,
    .theme--light,
    .v-list-item__content {
      color: #704ad1 !important;
    }
    .text {
      font-weight: 700;
      font-size: 13px;
      color: #704ad1 !important;
    }
  }
  .v-list-item--active-simlpe-item-normal {
    border-radius: 0px !important;
    margin-left: 10px !important;
    margin-top: 2px !important;
  }
}
.menu-crm .item-menu {
  margin-bottom: 0px !important;
  min-height: 40px !important;
  display: flex;
  align-items: center;
  white-space: nowrap;
  .group-content {
    width: 100% !important;
    .icon-group {
      margin-right: 1px !important;
    }
  }
  .v-list-item__icon {
    margin-right: 20px !important;
    gap: 10px;
    // height: 27px !important;
  }

  &:hover {
    background-color: rgba(112, 74, 209, 0.1) !important;
    .v-icon.v-icon,
    svg,
    .v-list-item__content {
      color: #704ad1 !important;
    }
  }
}
.v-list-item--active-simlpe-item {
  background-color: rgba(112, 74, 209, 0.1) !important;
  width: 100%;
  border-radius: 5px;
  /* padding: 0px; */
  .v-icon.v-icon,
  svg,
  .theme--light.v-icon,
  .theme--light,
  .v-list-item__content {
    color: #704ad1 !important;
  }
  .text {
    font-weight: 700;
    font-size: 13px;
    color: #704ad1 !important;
  }
  a {
    padding: 0px 8px;
  }
}
</style>
